<template>
  <div class="container">
    <!-- 广告 -->
    <ad></ad>

    <div class="content">
      <div class="content__body">
        <dl v-for="(item, index) in traceSourceList" :key="index">
          <dt>{{ item.key }}:</dt>
          <dd>{{ item.value }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import ad from '@/components/ad/index'
import { traceSourceVerify } from '@/api/index'

export default {
  name: 'Home',
  components: {
    ad
  },
  data() {
    return {
      traceSourceList: []
    }
  },
  methods: {
    getTraceSourceVerify() {
      traceSourceVerify().then(({ data }) => {
        this.traceSourceList = data.traceSourcePairs || []
      })
    }
  },
  created() {
    this.getTraceSourceVerify()
  }
}
</script>

<style lang="scss">
.container {
  padding-top: 107px;
  width: 100%;
  min-height: 1493px;
  height: 100vh;
  background: url('../../assets/home/bg.png') no-repeat center top / 100% auto
    #9f1f14;
}
.ad {
  margin: 0 auto 200px;
  width: 602px;
  height: 370px;
}
.content {
  margin: 0 auto;
  width: 602px;
  height: 560px;
  padding: 63px 56px 0;
  .content__body {
    height: 100%;
    overflow-y: auto;
  }
}
dl {
  display: flex;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.48);
  dt {
    flex: 0 0 130px;
    padding-right: 10px;
  }
}
</style>
