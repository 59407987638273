<template>
  <div class="ad">
    <img :src="firstAd.pictureUrl" @click="onSkipLink" />
  </div>
</template>

<script>
import { adList } from '@/api/ad'
import { sendTrack } from '@/utils/sendTrack'

export default {
  name: 'Ad',
  data() {
    return {
      firstAd: {}
    }
  },
  methods: {
    getAdList() {
      adList().then(({ data }) => {
        // 已发布状态
        if (Array.isArray(data)) {
          let list = data.filter(item => item.status == 1)
          this.firstAd = list[0]
          this.sendAdLog(this.firstAd, 1)
        }
      })
    },
    // 发送广告埋点
    sendAdLog(item = {}, operate) {
      sendTrack(process.env.VUE_APP_BASE_LOG + '/advertise/saveAdvertisesLog', {
        location: 1,
        advertiseTitle: item.advertiseTitle, // 广告标题
        advertiseType: item.advertiseType, //广告类型 1：图片 2：视频
        pictureUrl: item.pictureUrl, //图片地址
        videoUrl: item.videoUrl || '', //视频地址
        linkType: item.skipType, // 1:h5; 2：小程序
        originalId: item.originalId, //"小程序原始id"
        linkUrl: item.skipLink, //跳转链接
        operate // 操作：1：曝光 2：点击
      })
    },
    onSkipLink() {
      this.sendAdLog(this.firstAd, 2)
      window.location.href = this.firstAd.skipLink
    }
  },
  created() {
    this.getAdList()
  }
}
</script>

<style lang="scss">
.ad {
  margin: 0 auto 200px;
  width: 602px;
  height: 370px;
}
</style>
